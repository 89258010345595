<template>
  <model width="auto" title="Table" tag="表格">
    <h3>基础用法</h3>
    <p>lh-table 组件可以很自由方便地调整表格的颜色。</p>
    <lh-table>
      <lh-tr backgroundColor="#409EFF">
        <lh-th colBackgroundColor="#409EFF"></lh-th>
        <lh-th>周日</lh-th>
        <lh-th>周一</lh-th>
        <lh-th>周二</lh-th>
        <lh-th>周三</lh-th>
        <lh-th>周四</lh-th>
        <lh-th>周五</lh-th>
        <lh-th>周六</lh-th>
      </lh-tr>
      <lh-tr v-for="(itemList,index) of income" :key="index">
        <lh-td>收益{{ index+1 }}</lh-td>
        <lh-td v-for="(item,index) of itemList" :key="index" 
        :color="item<0?'green':(item>0?'red':'gray')"
        :backgroundColor="item<0?'green':(item>0?'red':'gray')">
          {{ item>0?('+'+item):item }}
        </lh-td>
      </lh-tr>
    </lh-table>
    <div class="code">
      <lh-collapse>
        <div slot="title">示例代码</div>
        <lh-code-editor v-model="code1" :readonly="true"></lh-code-editor>
      </lh-collapse>
    </div>

    <h3>属性参考表</h3>

    <h4>lh-table:</h4>
    <lh-table>
      <lh-tr>
        <lh-th>参数</lh-th>
        <lh-th>说明</lh-th>
        <lh-th>类型</lh-th>
        <lh-th>可选值</lh-th>
        <lh-th>默认值</lh-th>
      </lh-tr>
      <lh-tr>
        <lh-td>width</lh-td>
        <lh-td>表格宽度</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>auto</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>height</lh-td>
        <lh-td>表格高度</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>auto</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>padding</lh-td>
        <lh-td>内边距</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>5px</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>color</lh-td>
        <lh-td>表格字体颜色</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>black</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>backgroundColor</lh-td>
        <lh-td>表格背景颜色</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>auto</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>fontSize</lh-td>
        <lh-td>表格字体大小</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>15px</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>align</lh-td>
        <lh-td>表格对齐方式</lh-td>
        <lh-td>String</lh-td>
        <lh-td>left | right | center</lh-td>
        <lh-td>left</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>valign</lh-td>
        <lh-td>表格垂直对齐方式</lh-td>
        <lh-td>String</lh-td>
        <lh-td>top | middle | bottom | baseline</lh-td>
        <lh-td>middle</lh-td>
      </lh-tr>
    </lh-table>

    <h4>lh-tr:</h4>
    <lh-table>
      <lh-tr>
        <lh-th>参数</lh-th>
        <lh-th>说明</lh-th>
        <lh-th>类型</lh-th>
        <lh-th>可选值</lh-th>
        <lh-th>默认值</lh-th>
      </lh-tr>
      <lh-tr>
        <lh-td>fontSize</lh-td>
        <lh-td>当前行的字体大小</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>null</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>padding</lh-td>
        <lh-td>当前行的内边距</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>null</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>color</lh-td>
        <lh-td>当前行的字体颜色</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>null</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>backgroundColor</lh-td>
        <lh-td>当前行的背景颜色</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>null</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>height</lh-td>
        <lh-td>当前行的高度</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>auto</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>align</lh-td>
        <lh-td>当前行的对齐方式</lh-td>
        <lh-td>String</lh-td>
        <lh-td>left | right | center</lh-td>
        <lh-td>null</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>valign</lh-td>
        <lh-td>当前行的垂直对齐方式</lh-td>
        <lh-td>String</lh-td>
        <lh-td>top | middle | bottom | baseline</lh-td>
        <lh-td>null</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>zIndex</lh-td>
        <lh-td>当前行的层级</lh-td>
        <lh-td>Number</lh-td>
        <lh-td>-</lh-td>
        <lh-td>null</lh-td>
      </lh-tr>
    </lh-table>

    <h4>lh-th:</h4>
    <lh-table>
      <lh-tr>
        <lh-th>参数</lh-th>
        <lh-th>说明</lh-th>
        <lh-th>类型</lh-th>
        <lh-th>可选值</lh-th>
        <lh-th>默认值</lh-th>
      </lh-tr>
      <lh-tr>
        <lh-td>width</lh-td>
        <lh-td>当前列的宽度</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>auto</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>fontSize</lh-td>
        <lh-td>当前格子的字体大小</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>null</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>color</lh-td>
        <lh-td>当前格子的字体颜色</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>null</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>backgroundColor</lh-td>
        <lh-td>当前格子的背景颜色</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>null</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>colFontSize</lh-td>
        <lh-td>当前列的字体大小</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>null</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>colColor</lh-td>
        <lh-td>当前列的字体颜色</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>null</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>colBackgroundColor</lh-td>
        <lh-td>当前列的背景颜色</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>null</lh-td>
      </lh-tr>
    </lh-table>

    <h4>lh-td:</h4>
    <lh-table>
      <lh-tr>
        <lh-th>参数</lh-th>
        <lh-th>说明</lh-th>
        <lh-th>类型</lh-th>
        <lh-th>可选值</lh-th>
        <lh-th>默认值</lh-th>
      </lh-tr>
      <lh-tr>
        <lh-td>fontSize</lh-td>
        <lh-td>当前格子的字体大小</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>null</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>color</lh-td>
        <lh-td>当前格子的字体颜色</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>null</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>backgroundColor</lh-td>
        <lh-td>当前格子的背景颜色</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>null</lh-td>
      </lh-tr>
    </lh-table>
  </model>
</template>

<script>
import Model from '../../components/Model.vue'
export default {
  name:"TableView",
  components: { Model },
  data(){
    return {
      income:[
        [0.00,0.05,-0.10,-5.00,10.00,-2.00,0.00],
        [0.00,-2.00,4.01,-1.00,-2.10,7.09,0.00],
        [0.00,-3.00,12.15,-3.55,-4.00,2.00,0.00],
        [0.00,2.98,1.00,2.15,-3.00,-20.22,0.00],
        [0.00,9.03,-3.07,3.00,9.89,2.50,0.00]
      ],
      code1:
        "<template>\n"+
        "<lh-table>\n"+
        "  <lh-tr backgroundColor=\"#409EFF\">\n"+
        "    <lh-th colBackgroundColor=\"#409EFF\"></lh-th>\n"+
        "    <lh-th>周日</lh-th>\n"+
        "    <lh-th>周一</lh-th>\n"+
        "    <lh-th>周二</lh-th>\n"+
        "    <lh-th>周三</lh-th>\n"+
        "    <lh-th>周四</lh-th>\n"+
        "    <lh-th>周五</lh-th>\n"+
        "    <lh-th>周六</lh-th>\n"+
        "  </lh-tr>\n"+
        "  <lh-tr v-for=\"(itemList,index) of income\" :key=\"index\">\n"+
        "    <lh-td>收益{{ index+1 }}</lh-td>\n"+
        "    <lh-td v-for=\"(item,index) of itemList\" :key=\"index\" \n"+
        "    :color=\"item<0?'green':(item>0?'red':'gray')\"\n"+
        "    :backgroundColor=\"item<0?'green':(item>0?'red':'gray')\">\n"+
        "      {{ item>0?('+'+item):item }}\n"+
        "    </lh-td>\n"+
        "  </lh-tr>\n"+
        "</lh-table>\n"+
        "</template>\n\n"+
        "<script>\n"+
        "export default {\n"+
        "  data(){\n"+
        "    return {\n"+
        "      income:[\n"+
        "        [0.00,0.05,-0.10,-5.00,10.00,-2.00,0.00],\n"+
        "        [0.00,-2.00,4.01,-1.00,-2.10,7.09,0.00],\n"+
        "        [0.00,-3.00,12.15,-3.55,-4.00,2.00,0.00],\n"+
        "        [0.00,2.98,1.00,2.15,-3.00,-20.22,0.00],\n"+
        "        [0.00,9.03,-3.07,3.00,9.89,2.50,0.00]\n"+
        "      ]   \n"+
        "    }\n"+
        "  }\n"+
        "}\n"+
        "</scr"+"ipt>"
    }
  }
}
</script>

<style lang="less" scoped>
.code{
  display: flex;
  flex-direction: column;
}
</style>