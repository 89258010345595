<template>
  <div class="lhUI">
    <div class="head">
      <div class="left">
        <div class="logo">
          <span :style="{fontSize:logoFontSize}" class="iconfont lh-icon-logo"></span>
        </div>
        <div class="title" :style="{fontSize:titleFontSize}">
          lhUI
        </div>
      </div>
      <div class="middle" style="width:30%">
        <lh-input width="100%" v-model="searchInput" placeholder="搜索目录" :fetchTips="search" :clearable="true">
          <template slot-scope="scope">
            <lh-router-link width="100%" @click="searchInput=scope.item.tag" :to="scope.item.path">
              <div>{{ scope.item.tag }}</div>
            </lh-router-link>
          </template>
        </lh-input>
      </div>
      <div class="right">
        <lh-select v-model="currentVersion">
          <lh-option value="1.0.2">1.0.2</lh-option>
          <lh-option value="2.0.0">2.0.0</lh-option>
        </lh-select>
      </div>
    </div>
    <!-- <div class="content">
      <div class="nav">
        <lh-nav-menu>
          <lh-menu-group icon="icon-log iconfont">
            <div slot="groupName">更新日志</div>
            <template v-for="(item,index) of logRoutes">
              <lh-menu-item :to="item.path" :key="index">{{item.meta.tag}}</lh-menu-item>
            </template>
          </lh-menu-group>
          <lh-menu-group icon="icon-guide iconfont">
            <div slot="groupName">开发指南</div>
            <template v-for="(item,index) of guideRoutes">
              <lh-menu-item :to="item.path" :key="index">{{item.meta.tag}}</lh-menu-item>
            </template>
          </lh-menu-group>
          <lh-menu-group icon="icon-component iconfont">
            <div slot="groupName">组件</div>
            <template v-for="(item,index) of lhComponentsRoutes">
              <lh-menu-item :to="item.path" :key="index">{{item.meta.tag}}</lh-menu-item>
            </template>
          </lh-menu-group>
          <lh-menu-group icon="icon-feedback iconfont">
            <div slot="groupName">意见反馈</div>
            <template v-for="(item,index) of feedbackRoutes">
              <lh-menu-item :to="item.path" :key="index">{{item.meta.tag}}</lh-menu-item>
            </template>
          </lh-menu-group>
        </lh-nav-menu>
      </div>
      <div class="view">
        <router-view/>
      </div>
    </div> -->
    <lh-size-layout>
      <div class="nav" slot="left">
        <lh-nav-menu>
          <lh-menu-group v-for="(routeItem,index) of routeList" :icon="'icon-'+routeItem.icon+' iconfont'" :key="'g'+index">
            <div slot="groupName">{{ routeItem.type }}</div>
            <template v-for="(item,i) of routeItem.routes">
              <lh-menu-item v-if="betweenVesions(currentVersion,item.meta.startVersion,item.meta.endVersion)" :to="item.path" :key="'r'+i">
                {{item.meta.tag}}
              </lh-menu-item>
            </template>
          </lh-menu-group>
          <!-- <lh-menu-group icon="icon-log iconfont">
            <div slot="groupName">更新日志</div>
            <template v-for="(item,index) of logRoutes">
              <lh-menu-item :to="item.path" :key="index">{{item.meta.tag}}</lh-menu-item>
            </template>
          </lh-menu-group> -->
          <!-- <lh-menu-group icon="icon-guide iconfont">
            <div slot="groupName">开发指南</div>
            <template v-for="(item,index) of guideRoutes">
              <lh-menu-item :to="item.path" :key="index">{{item.meta.tag}}</lh-menu-item>
            </template>
          </lh-menu-group>
          <lh-menu-group icon="icon-component iconfont">
            <div slot="groupName">组件</div>
            <template v-for="(item,index) of lhComponentsRoutes">
              <lh-menu-item :to="item.path" :key="index">{{item.meta.tag}}</lh-menu-item>
            </template>
          </lh-menu-group>
          <lh-menu-group icon="icon-feedback iconfont">
            <div slot="groupName">意见反馈</div>
            <template v-for="(item,index) of feedbackRoutes">
              <lh-menu-item :to="item.path" :key="index">{{item.meta.tag}}</lh-menu-item>
            </template>
          </lh-menu-group> -->
        </lh-nav-menu>
      </div>
      <div class="view">
        <router-view/>
      </div>
    </lh-size-layout>
  </div>
</template>

<script>
import logRoutes from "./routes/logRoutes"
import guideRoutes from "./routes/guideRoutes"
import lhComponentsRoutes from "./routes/lhComponentsRoutes"
import feedbackRoutes from "./routes/feedbackRoutes"
import routes from "./routes/index"
import { betweenVesions } from "./utils/Index"
export default {
  name:"LhUI",
  data(){
    return {
      // logRoutes:logRoutes,
      // guideRoutes:guideRoutes,
      // lhComponentsRoutes:lhComponentsRoutes,
      // feedbackRoutes:feedbackRoutes,
      routeList:[],
      searchInput:"",
      logoFontSize:"70px",
      titleFontSize:"35px",
      currentVersion:"2.0.0"
    }
  },
  methods:{
    search:function(str,callback){
      if(str==""){
        callback(null);
        return;
      }
      let data=[];
      for(let item of routes){
        let tag=item.meta.tag.toLowerCase();
        let lowerStr=str.toLowerCase();
        let index=tag.indexOf(lowerStr);
        if(index>=0){
          data.push({path:item.path,tag:item.meta.tag});
        }
      }
      callback(data);
    },
    handleResize:function(){
      this.logoFontSize=document.documentElement.clientWidth/4*0.3+"px";
      this.titleFontSize=document.documentElement.clientWidth/4*0.2+"px";
    },
    betweenVesions:betweenVesions
  },
  watch:{
    currentVersion:{
      handler:function(){
        sessionStorage.setItem("version",this.currentVersion);
        const event=new CustomEvent("versionChange");
        window.dispatchEvent(event);
      },
      immediate:true
    }
  },
  mounted:function(){
    this.handleResize();
    window.addEventListener("resize",this.handleResize);
    this.routeList=[
      {
        type:"更新日志",
        icon:"log",
        routes:logRoutes
      },
      {
        type:"开发指南",
        icon:"guide",
        routes:guideRoutes
      },
      {
        type:"组件",
        icon:"component",
        routes:lhComponentsRoutes
      },
      {
        type:"意见反馈",
        icon:"feedback",
        routes:feedbackRoutes
      }
    ]
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style src="../../assets/iconfont.css"></style>
<style lang="less" scoped>
.lhUI{
  height: 100vh;
  //border: solid;
  .head{
    width:100%;
    // height: 15%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
    .left{
      display: flex;
      align-items: flex-end;
      .logo{
        padding: 6px;
        // .lh-icon-logo{
        //   font-size: 70px;
        // }
      }
      .title{
        padding: 10px;
        // font-size: 35px;
        font-weight: 700;
        font-family:'Segoe UI';
      }
    }
  }
  .content{
    //border: solid;
    display: flex;
    justify-content: space-around;
    height: 85%;
    .nav{
      height: 100vh;
      //border: solid;
      overflow: auto;
      padding: 10px;
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
      margin:5px;
    }
    .view{
      height: 100vh;
      overflow: auto;
      // border: solid;
    }
  }
}
</style>