<template>
  <model width="auto" title="Input" tag="输入框">
    <h3>基础用法</h3>
    <lh-input v-model="inputValue"/>
    <div class="code">
      <lh-collapse>
        <div slot="title">示例代码</div>
        <lh-code-editor v-model="code1"/>
      </lh-collapse>
    </div>
    <h3>输入提示</h3>
    <h4>默认模版：</h4>
    <p>通过函数属性 fetchTips 返回提示信息数据实现输入提示功能。</p>
    <lh-input v-model="inputValue2" :fetchTips="fetchTips" :clearable="true"/>
    <div class="code">
      <lh-collapse>
        <div slot="title">示例代码</div>
        <lh-code-editor v-model="code2" :readonly="true"/>
      </lh-collapse>
    </div>
    <h4>自定义模版：</h4>
    <p>通过作用域插槽自定义提示模版。</p>
    <lh-input v-model="inputValue3" :fetchTips="fetchTips" :clearable="true">
      <template slot-scope="scope">自定义：{{scope.item}}</template>
    </lh-input>
    <div class="code">
      <lh-collapse>
        <div slot="title">示例代码</div>
        <lh-code-editor v-model="code3" :readonly="true"/>
      </lh-collapse>
    </div>
    <h3>组件图标</h3>
    <p>通过 icon 属性可以设置按钮图标。通过设置 icon 属性为图标的类名即可设置按钮图标。图标类名请参考 <lh-router-link to="/icon" fontSize="18px" color="#409EFF">Icon 图标</lh-router-link> 。</p>
    <div style="width:300px">
      <div><lh-input icon="lh-icon-user" placeholder="请输入账号" width="100%"/></div>
      <div><lh-input icon="lh-icon-password" type="password" placeholder="请输入密码" width="100%"/></div>
      <div><lh-input icon="lh-icon-verify" placeholder="验证码" width="100%"/></div>
    </div>
    <div class="code">
      <lh-collapse>
        <div slot="title">示例代码</div>
        <lh-code-editor v-model="code4" :readonly="true"/>
      </lh-collapse>
    </div>
    <h3>属性事件参考表</h3>
    <h4>属性参考表：</h4>
    <lh-table>
      <lh-tr>
        <lh-th>参数</lh-th>
        <lh-th width="300px">说明</lh-th>
        <lh-th>类型</lh-th>
        <lh-th>可选值</lh-th>
        <lh-th>默认值</lh-th>
      </lh-tr>
      <lh-tr>
        <lh-td>value(v-model)</lh-td>
        <lh-td>输入值</lh-td>
        <lh-td>-</lh-td>
        <lh-td>-</lh-td>
        <lh-td></lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>width</lh-td>
        <lh-td>组件宽度</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>auto</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>fontSize</lh-td>
        <lh-td>字体大小</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>20px</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>margin</lh-td>
        <lh-td>组件外边距</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>2px</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>icon</lh-td>
        <lh-td>组件图标</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>null</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>placeholder</lh-td>
        <lh-td>占位文字</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>请输入</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>type</lh-td>
        <lh-td>输入组件类型</lh-td>
        <lh-td>String</lh-td>
        <lh-td>text | password | number</lh-td>
        <lh-td>text</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>fetchTips</lh-td>
        <lh-td>
          返回输入提示数据的函数，此函数有两个参数，第一个参数表示输入的值，
          第二个参数为返回函数，通过此函数返回提示的数据。
        </lh-td>
        <lh-td>Function</lh-td>
        <lh-td>-</lh-td>
        <lh-td>null</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>clearable</lh-td>
        <lh-td>是否一键清除</lh-td>
        <lh-td>Boolean</lh-td>
        <lh-td>true | false</lh-td>
        <lh-td>false</lh-td>
      </lh-tr>
    </lh-table>
    <h4>事件参考表：</h4>
    <lh-table>
      <lh-tr>
        <lh-th>事件名称</lh-th>
        <lh-th>说明</lh-th>
        <lh-th>回调参数</lh-th>
      </lh-tr>
      <lh-tr>
        <lh-td>change</lh-td>
        <lh-td>当绑定值变化时触发的事件</lh-td>
        <lh-td>更新后的值</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>select</lh-td>
        <lh-td>选择输入提示触发的事件</lh-td>
        <lh-td>选择的下标</lh-td>
      </lh-tr>
    </lh-table>
  </model>
</template>

<script>
import Model from '../../components/Model.vue'
const tips=[
  "输入提示1...",
  "输入提示2...",
  "这是提示3...",
  "这是提示4..."
]
export default {
  components: { Model },
  data(){
    return{
      inputValue:"",
      inputValue2:"",
      inputValue3:"",
      code1:
        "<template>\n"+
        " <lh-input v-model=\"inputValue\"/>\n"+
        "</template>\n\n"+
        "<script>\n"+
        "export default {\n"+
        "  data(){\n"+
        "    return{\n"+
        "      inputValue:\"\"\n"+
        "    }\n"+
        "  }\n"+
        "}\n"+
        "</scr"+"ipt>",
      code2:
        "<template>\n"+
        "<lh-input v-model=\"inputValue\" :fetchTips=\"fetchTips\" :clearable=\"true\"/>\n"+
        "</template>\n\n"+
        "<script>\n"+
        "const tips=[\n"+
        "  \"输入提示1...\",\n"+
        "  \"输入提示2...\",\n"+
        "  \"这是提示3...\",\n"+
        "  \"这是提示4...\"\n"+
        "]\n"+
        "export default {\n"+
        "  data(){\n"+
        "    return{\n"+
        "      inputValue:\"\"\n"+
        "    }\n"+
        "  },\n"+
        "  methods:{\n"+
        "    fetchTips:function(str,callback){\n"+
        "      let callbackTips=[];\n"+
        "      for(let item of tips){\n"+
        "        let index=item.indexOf(str);\n"+
        "        if(index>=0){\n"+
        "          callbackTips.push(item);\n"+
        "        }\n"+
        "      }\n"+
        "      callback(callbackTips);\n"+
        "    }\n"+
        "  }\n"+
        "}\n"+
        "</scr"+"ipt>",
      code3:
        "<template>\n"+
        "<lh-input v-model=\"inputValue\" :fetchTips=\"fetchTips\" :clearable=\"true\">\n"+
        "  <template slot-scope=\"scope\">自定义：{{scope.item}}</template>\n"+
        "</lh-input>\n"+
        "</template>\n\n"+
        "<script>\n"+
        "const tips=[\n"+
        "  \"输入提示1...\",\n"+
        "  \"输入提示2...\",\n"+
        "  \"这是提示3...\",\n"+
        "  \"这是提示4...\"\n"+
        "]\n"+
        "export default {\n"+
        "  data(){\n"+
        "    return{\n"+
        "      inputValue:\"\"\n"+
        "    }\n"+
        "  },\n"+
        "  methods:{\n"+
        "    fetchTips:function(str,callback){\n"+
        "      let callbackTips=[];\n"+
        "      for(let item of tips){\n"+
        "        let index=item.indexOf(str);\n"+
        "        if(index>=0){\n"+
        "          callbackTips.push(item);\n"+
        "        }\n"+
        "      }\n"+
        "      callback(callbackTips);\n"+
        "    }\n"+
        "  }\n"+
        "}\n"+
        "</scr"+"ipt>",
      code4:
        "<template>\n"+
        "<div style=\"width:300px\">\n"+
        "  <div><lh-input icon=\"lh-icon-user\" placeholder=\"请输入账号\" width=\"100%\"/></div>\n"+
        "  <div><lh-input icon=\"lh-icon-password\" placeholder=\"请输入密码\" width=\"100%\" type=\"password\"/></div>\n"+
        "  <div><lh-input icon=\"lh-icon-verify\" placeholder=\"验证码\" width=\"100%\"/></div>\n"+
        "</div>\n"+
        "</template>"
    }
  },
  methods:{
    fetchTips:function(str,callback){
      //if(str==""){
      //  callback(null);
      //  return;
      //}
      let callbackTips=[];
      for(let item of tips){
        let index=item.indexOf(str);
        if(index>=0){
          callbackTips.push(item);
        }
      }
      callback(callbackTips);
    }
  }
}
</script>

<style lang="less" scoped>
.code{
  display: flex;
  flex-direction: column;
}
</style>