<template>
  <model width="auto" title="RouterLink" tag="路由跳转">
    <h3>基础用法</h3>
    <div>
      <lh-router-link padding="10px" to="/button">跳转到 Button 界面</lh-router-link>
      <lh-router-link padding="10px" to="/routerLink">当前路由</lh-router-link>
    </div>
    <div>
      <lh-router-link margin="10px" padding="5px" activeBorder="left" to="/routerLink">当前路由(左边框)</lh-router-link>
      <lh-router-link margin="10px" padding="5px" activeBorder="right" to="/routerLink">当前路由(右边框)</lh-router-link>
    </div>
    <div>
      <lh-router-link margin="10px" padding="10px" activeBorder="top" to="/routerLink">当前路由(上边框)</lh-router-link>
      <lh-router-link margin="10px" padding="10px" activeBorder="bottom" to="/routerLink">当前路由(下边框)</lh-router-link>
    </div>
    <div class="code">
      <lh-collapse>
        <div slot="title">示例代码</div>
        <lh-code-editor v-model="code1" maxWidth="750px" :readonly="true"/>
      </lh-collapse>
    </div>
    <p>此组件除可用于跳转路由以外，亦可用于跳转网页，且可自定义激活状态。</p>
    <lh-router-link to="https://www.baidu.com" targer="_blank" margin="10px">跳转到百度</lh-router-link>
    <lh-router-link :active="true" margin="10px">自定义激活</lh-router-link>
    <div class="code">
      <lh-collapse>
        <div slot="title">示例代码</div>
        <lh-code-editor v-model="code2" :readonly="true"/>
      </lh-collapse>
    </div>
    <h3>属性参考表</h3>
    <lh-table>
      <lh-tr>
        <lh-th>参数</lh-th>
        <lh-th>说明</lh-th>
        <lh-th>类型</lh-th>
        <lh-th>可选值</lh-th>
        <lh-th>默认值</lh-th>
      </lh-tr>
      <lh-tr>
        <lh-td>to</lh-td>
        <lh-td>路由或网址</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td></lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>padding</lh-td>
        <lh-td>组件内边距</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>0px</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>margin</lh-td>
        <lh-td>组件外边距</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>0px</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>activeBorder</lh-td>
        <lh-td>激活时的边框</lh-td>
        <lh-td>String</lh-td>
        <lh-td>none | left | right | top | bottom</lh-td>
        <lh-td>none</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>activeColor</lh-td>
        <lh-td>激活颜色</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>#409EFF</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>active</lh-td>
        <lh-td>自定义激活状态</lh-td>
        <lh-td>Boolean</lh-td>
        <lh-td>true | false</lh-td>
        <lh-td>false</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>color</lh-td>
        <lh-td>字体颜色</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>black</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>fontSize</lh-td>
        <lh-td>字体大小</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>15px</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>fontWeight</lh-td>
        <lh-td>字体粗细</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>480</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>width</lh-td>
        <lh-td>组件宽度</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>auto</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>target</lh-td>
        <lh-td>跳转目标</lh-td>
        <lh-td>String</lh-td>
        <lh-td>_blank | _self</lh-td>
        <lh-td>_self</lh-td>
      </lh-tr>
    </lh-table>
  </model>
</template>

<script>
import Model from '../../components/Model.vue'
export default {
  name:"RouterLinkView",
  components: { Model },
  data(){
    return{
      code1:
        "<template>\n"+
        "<div>\n"+
        "  <div>\n"+
        "    <lh-router-link padding=\"10px\" to=\"/button\">跳转到 Button 界面</lh-router-link>\n"+
        "    <lh-router-link padding=\"10px\" to=\"/routerLink\">当前路由</lh-router-link>\n"+
        "  </div>\n"+
        "  <div>\n"+
        "    <lh-router-link margin=\"10px\" padding=\"5px\" activeBorder=\"left\" to=\"/routerLink\">当前路由(左边框)</lh-router-link>\n"+
        "    <lh-router-link margin=\"10px\" padding=\"5px\" activeBorder=\"right\" to=\"/routerLink\">当前路由(右边框)</lh-router-link>\n"+
        "  </div>\n"+
        "  <div>\n"+
        "    <lh-router-link margin=\"10px\" padding=\"10px\" activeBorder=\"top\" to=\"/routerLink\">当前路由(上边框)</lh-router-link>\n"+
        "    <lh-router-link margin=\"10px\" padding=\"10px\" activeBorder=\"bottom\" to=\"/routerLink\">当前路由(下边框)</lh-router-link>\n"+
        "  </div>\n"+
        "</div>\n"+
        "</template>",
      code2:
        "<template>\n"+
        "<div>\n"+
        "  <lh-router-link to=\"https://www.baidu.com\" targer=\"_blank\" margin=\"10px\">跳转到百度</lh-router-link>\n"+
        "  <lh-router-link :active=\"true\" margin=\"10px\">自定义激活</lh-router-link>\n"+
        "</div>\n"+
        "</template>"
    }
  }
}
</script>

<style lang="less" scoped>
.code{
  display: flex;
  flex-direction: column;
}
</style>