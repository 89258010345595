<template>
  <model width="auto" title="CodeEditor" tag="代码编辑器">
    <h3>基础用法</h3>
    <p>CodeEditor组件用于展示或编辑代码，通过 v-model 绑定数据即可显示或编辑代码。以下为使用示例：</p>
    <div><lh-code-editor v-model="codeShow"></lh-code-editor></div>

    <div class="code">
      <lh-collapse>
        <div slot="title">示例代码</div>
        <lh-code-editor readonly v-model="code"></lh-code-editor>
      </lh-collapse>
    </div>
    
    <p>通过 copyButton 和 downloadButton 属性可以控制“复制按钮”和“下载按钮”的显示：</p>
    <div><lh-code-editor :copyButton="true" :downloadButton="true" v-model="codeShow"></lh-code-editor></div>

    <div class="code">
      <lh-collapse>
        <div slot="title">示例代码</div>
        <lh-code-editor readonly v-model="code2" maxWidth="750px"></lh-code-editor>
      </lh-collapse>
    </div>
  
    <h3>属性参考表</h3>
    <lh-table>
      <lh-tr>
        <lh-th>参数</lh-th>
        <lh-th>说明</lh-th>
        <lh-th>类型</lh-th>
        <lh-th>可选值</lh-th>
        <lh-th>默认值</lh-th>
      </lh-tr>
      <lh-tr>
        <lh-td>value(v-model)</lh-td>
        <lh-td>代码数据</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>hello world</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>language</lh-td>
        <lh-td>代码语言</lh-td>
        <lh-td>String</lh-td>
        <lh-td>java | js</lh-td>
        <lh-td>js</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>maxWidth</lh-td>
        <lh-td>最大宽度</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>none</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>color</lh-td>
        <lh-td>代码基础颜色</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>#ccc</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>backgroundColor</lh-td>
        <lh-td>背景颜色</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>#2d2d2d</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>readonly</lh-td>
        <lh-td>是否只读，控制是否可以编辑</lh-td>
        <lh-td>Boolean</lh-td>
        <lh-td>true | false</lh-td>
        <lh-td>false</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>copyButton</lh-td>
        <lh-td>是否显示复制按钮</lh-td>
        <lh-td>Boolean</lh-td>
        <lh-td>true | false</lh-td>
        <lh-td>false</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>downloadButton</lh-td>
        <lh-td>是否显示下载按钮</lh-td>
        <lh-td>Boolean</lh-td>
        <lh-td>true | false</lh-td>
        <lh-td>false</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>fileName</lh-td>
        <lh-td>下载文件的名称，需要显示“下载按钮”此属性才有意义</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>hello.txt</lh-td>
      </lh-tr>
    </lh-table>
  </model>
</template>

<script>
import Model from '../../components/Model.vue'
export default {
  components: { Model },
  name:"CodeEditorView",
  data(){
    return {
      codeShow:
        "Hello world!\n"+
        "Hello world!\n"+
        "Hello world!",
      code:
        "<template>\n"+
        "  <lh-code-editor v-model=\"code\"></lh-code-editor>\n"+
        "</template>\n\n"+
        "<script>\n"+
        "export default {\n"+
        "  data(){\n"+
        "    return {\n"+
        "      code:\n"+
        "        \"Hello world!\\n\"+\n"+
        "        \"Hello world!\\n\"+\n"+
        "        \"Hello world!\"\n"+
        "    }\n"+
        "  }\n"+
        "}\n"+
        "</scrip"+"t>",
      code2:
        "<template>\n"+
        "  <lh-code-editor :copyButton=\"true\" :downloadButton=\"true\" v-model=\"code\"></lh-code-editor>\n"+
        "</template>\n\n"+
        "<script>\n"+
        "export default {\n"+
        "  data(){\n"+
        "    return {\n"+
        "      code:\n"+
        "        \"Hello world!\\n\"+\n"+
        "        \"Hello world!\\n\"+\n"+
        "        \"Hello world!\"\n"+
        "    }\n"+
        "  }\n"+
        "}\n"+
        "</scrip"+"t>"
    }
  }
}
</script>

<style lang="less" scoped>
.code{
  display: flex;
  flex-direction: column;
}
</style>