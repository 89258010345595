<template>
  <model width="auto" title="Select" tag="选择器">
    <h3>基础用法</h3>
    <lh-select v-model="selectValue">
      <lh-option :value="1">选项1</lh-option>
      <lh-option :value="2">选项2</lh-option>
      <lh-option :value="3">选项3</lh-option>
    </lh-select>
    <div class="code">
      <lh-collapse>
        <div slot="title">示例代码</div>
        <lh-code-editor v-model="code1" :readonly="true"></lh-code-editor>
      </lh-collapse>
    </div>
    <h3>组件样式</h3>
    <p style="text-indent: 2em;">通过 fontSize 属性和 padding 属性控制组件的大小。通过 color 属性控制字体颜色，通过 backgroundColor 属性控制组件背景颜色。</p>
    <lh-select fontSize="15px">
      <lh-option>选项1</lh-option>
      <lh-option>选项2</lh-option>
      <lh-option>选项3</lh-option>
    </lh-select>
    <lh-select fontSize="20px" backgroundColor="#bfbfbf" color="white">
      <lh-option>选项1</lh-option>
      <lh-option>选项2</lh-option>
      <lh-option>选项3</lh-option>
    </lh-select>
    <lh-select fontSize="25px" backgroundColor="#409EFF" color="white">
      <lh-option>选项1</lh-option>
      <lh-option>选项2</lh-option>
      <lh-option>选项3</lh-option>
    </lh-select>
    <div class="code">
      <lh-collapse>
        <div slot="title">示例代码</div>
        <lh-code-editor v-model="code2" :readonly="true"></lh-code-editor>
      </lh-collapse>
    </div>
    <h3>属性参考表</h3>
    <lh-table>
      <lh-tr>
        <lh-th>参数</lh-th>
        <lh-th>说明</lh-th>
        <lh-th>类型</lh-th>
        <lh-th>可选值</lh-th>
        <lh-th>默认值</lh-th>
      </lh-tr>
      <lh-tr>
        <lh-td>value(v-model)</lh-td>
        <lh-td>选择的值</lh-td>
        <lh-td>-</lh-td>
        <lh-td>-</lh-td>
        <lh-td>null</lh-td>
      </lh-tr>
      <lh-tr v-if="betweenVesions(version,'2.0.0',null)">
        <lh-td>disabled</lh-td>
        <lh-td>是否禁用组件</lh-td>
        <lh-td>Boolean</lh-td>
        <lh-td>true | false</lh-td>
        <lh-td>false</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>placeholder</lh-td>
        <lh-td>没有选择时占位的文字</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>请选择</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>fontSize</lh-td>
        <lh-td>文字大小</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>15px</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>padding</lh-td>
        <lh-td>组件内边距</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>3px</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>margin</lh-td>
        <lh-td>组件外边距</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>2px</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>color</lh-td>
        <lh-td>文字颜色</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>black</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>backgroundColor</lh-td>
        <lh-td>组件背景颜色</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>white</lh-td>
      </lh-tr>
    </lh-table>
  </model>
</template>

<script>
import Model from '../../components/Model.vue'
import { betweenVesions } from '../../utils/Index';
export default {
  name:"SelectView",
  components: { Model },
  data(){
    return {
      version:"2.0.0",
      code1:
        "<template>\n"+
        " <lh-select v-model=\"selectValue\">\n"+
        "   <lh-option :value=\"1\">选项1</lh-option>\n"+
        "   <lh-option :value=\"2\">选项2</lh-option>\n"+
        "   <lh-option :value=\"3\">选项3</lh-option>\n"+
        " </lh-select>\n"+
        "</template>\n\n"+
        "<script>\n"+
        "export default {\n"+
        "  data(){\n"+
        "    return {\n"+
        "      selectValue:null\n"+
        "    }\n"+
        "  }\n"+
        "}\n"+
        "</scr"+"ipt>",
      code2:
        "<template>\n"+
        "<lh-select fontSize=\"15px\">\n"+
        "  <lh-option>选项1</lh-option>\n"+
        "  <lh-option>选项2</lh-option>\n"+
        "  <lh-option>选项3</lh-option>\n"+
        "</lh-select>\n"+
        "<lh-select fontSize=\"20px\" backgroundColor=\"#bfbfbf\" color=\"white\">\n"+
        "  <lh-option>选项1</lh-option>\n"+
        "  <lh-option>选项2</lh-option>\n"+
        "  <lh-option>选项3</lh-option>\n"+
        "</lh-select>\n"+
        "<lh-select fontSize=\"25px\" backgroundColor=\"#409EFF\" color=\"white\">\n"+
        "  <lh-option>选项1</lh-option>\n"+
        "  <lh-option>选项2</lh-option>\n"+
        "  <lh-option>选项3</lh-option>\n"+
        "</lh-select>\n"+
        "</template>",
      selectValue:null
    }
  },
  methods:{
    betweenVesions,
    versionChangeHandle:function(){
      this.version=sessionStorage.getItem("version");
    }
  },
  mounted:function(){
    this.versionChangeHandle();
    window.addEventListener("versionChange",this.versionChangeHandle);
  },
  beforeDestroy:function(){
    window.removeEventListener("versionChange",this.versionChangeHandle);
  }
}
</script>

<style lang="less" scoped>
.code{
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}
</style>