<template>
<model width="auto" title="v2.0.0" tag="2024-12-29">
    <h3>1.select组件添加滚动</h3>
    <h3>2.修复dialog的关闭按钮点击事件冒泡的bug</h3>
    <h3>3.修复loading遮罩无法遮住dialog组件的bug</h3>
    <h3>4.radio组件和select组件添加disabled属性</h3>
    <h3>5.nav-menu组件文字禁止换行</h3>
    <h3>6.新增drawer组件</h3>
    <h3>7.新增sizeLayout组件</h3>
</model>
</template>
  
<script>
import Model from '../../components/Model.vue'
export default {
    name:"V2-0-0",
    components: { Model }
}
</script>

<style lang="less" scoped>

</style>