<template>
  <model width="auto" title="Img" tag="图像">
    <h3>示例</h3>
    <lh-img width="250px" :src="require('../../static/example.jpg')"></lh-img>
    <div class="code">
      <lh-collapse>
        <div slot="title">示例代码</div>
        <lh-code-editor v-model="code1"></lh-code-editor>
      </lh-collapse>
    </div>
    <h3>属性参考表</h3>
    <lh-table>
      <lh-tr>
        <lh-th>参数</lh-th>
        <lh-th>说明</lh-th>
        <lh-th>类型</lh-th>
        <lh-th>可选值</lh-th>
        <lh-th>默认值</lh-th>
      </lh-tr>
      <lh-tr>
        <lh-td>width</lh-td>
        <lh-td>图像宽度</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>auto</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>height</lh-td>
        <lh-td>图像高度</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>auto</lh-td>
      </lh-tr>
      <lh-tr v-if="betweenVesions(version,'2.0.0',null)">
        <lh-td>margin</lh-td>
        <lh-td>外边距</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>0px</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>src</lh-td>
        <lh-td>图像资源路径</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td></lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>detail</lh-td>
        <lh-td>是否能查看详情</lh-td>
        <lh-td>Boolean</lh-td>
        <lh-td>true | false</lh-td>
        <lh-td>true</lh-td>
      </lh-tr>
    </lh-table>
  </model>
</template>

<script>
import Model from '../../components/Model.vue'
import { betweenVesions } from '../../utils/Index';
export default {
  name:"ImgView",
  components: { Model },
  data(){
      return{
        version:"2.0.0",
        code1:
          "<template>\n"+
          "  <lh-img width=\"250px\" :src=\"require('../../static/example.jpg')\"></lh-img>\n"+
          "</template>"
      }
  },
  methods:{
    betweenVesions,
    versionChangeHandle:function(){
      this.version=sessionStorage.getItem("version");
    }
  },
  mounted:function(){
    this.versionChangeHandle();
    window.addEventListener("versionChange",this.versionChangeHandle);
  },
  beforeDestroy:function(){
    window.removeEventListener("versionChange",this.versionChangeHandle);
  }
}
</script>

<style lang="less" scoped>
.code{
  display: flex;
  flex-direction: column;
}
</style>