<template>
  <model width="auto" title="Message" tag="信息提示">
    <h3>示例用法</h3>
    <div>
      <lh-button  @click="showMessage('success','成功提示')" backgroundColor="#67C23A">
        成功提示
      </lh-button>
      <lh-button @click="showMessage('error','错误提示')" backgroundColor="#F56E6E">
        错误提示
      </lh-button>
      <lh-button @click="showMessage('warn','警告提示')" backgroundColor="#E6A23C">
        警告提示
      </lh-button>
    </div>
    <div class="code">
      <lh-collapse>
        <div slot="title">示例代码</div>
        <lh-code-editor v-model="code1" :readonly="true"/>
      </lh-collapse>
    </div>
    <h3>函数方法参考表</h3>
    <lh-table>
      <lh-tr>
        <lh-th>方法名</lh-th>
        <lh-th>说明</lh-th>
        <lh-th width="450px">参数</lh-th>
        <lh-th>返回值</lh-th>
      </lh-tr>
      <lh-tr>
        <lh-td>lhMessage</lh-td>
        <lh-td>信息提示函数</lh-td>
        <lh-td>第一个参数是信息提示类型，可选值有(success | error | warn)，第二个参数是提示文本。</lh-td>
        <lh-td>-</lh-td>
      </lh-tr>
    </lh-table>
  </model>
</template>

<script>
import Model from '../../components/Model.vue'
export default {
  name:"MessageView",
  components: { Model },
  data(){
    return {
      code1:
        "<template>\n"+
        "<div>\n"+
        "  <lh-button @click=\"showMessage('success','成功提示')\" backgroundColor=\"#67C23A\">\n"+
        "    成功提示\n"+
        "  </lh-button>\n"+
        "  <lh-button @click=\"showMessage('error','错误提示')\" backgroundColor=\"#F56E6E\">\n"+
        "    错误提示\n"+
        "  </lh-button>\n"+
        "  <lh-button @click=\"showMessage('warn','警告提示')\" backgroundColor=\"#E6A23C\">\n"+
        "    警告提示\n"+
        "  </lh-button>\n"+
        "</div>\n"+
        "</template>\n\n"+
        "<script>\n"+
        "export default {\n"+
        "  methods:{\n"+
        "    showMessage:function(type,text){\n"+
        "      this.$lhMessage(type,text);\n"+
        "    }\n"+
        "  }\n"+
        "}\n"+
        "</scr"+"ipt>"
    }
  },
  methods:{
    showMessage:function(type,text){
      this.$lhMessage(type,text);
    }
  }
}
</script>

<style lang="less" scoped>
.code{
  display: flex;
  flex-direction: column;
}
</style>