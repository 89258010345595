<template>
<model width="auto" title="Dialog" tag="对话框">
  <h3>基础用法</h3>
  <lh-button @click="visible=true">点击打开对话框</lh-button>
  <lh-dialog :visible.sync="visible">
    <div slot="title">标题</div>
    这是对话框内容，默认插槽......<br/>
    这是对话框内容，默认插槽......<br/>
    这是对话框内容，默认插槽......<br/>
    <div slot="footer">
      <lh-button @click="visible=false" backgroundColor="#409EFF20" color="#409EFF" border="1px">取消</lh-button>
      <lh-button>确认</lh-button>
    </div>
  </lh-dialog>
  <div class="code">
    <lh-collapse>
      <div slot="title">示例代码</div>
      <lh-code-editor v-model="code1" :readonly="true"></lh-code-editor>
    </lh-collapse>
  </div>
  <h3>属性参考表</h3>
  <lh-table>
    <lh-tr>
      <lh-th>参数</lh-th>
      <lh-th>说明</lh-th>
      <lh-th>类型</lh-th>
      <lh-th>可选值</lh-th>
      <lh-th>默认值</lh-th>
    </lh-tr>
    <lh-tr>
      <lh-td>visible(.sync)</lh-td>
      <lh-td>是否弹出对话框</lh-td>
      <lh-td>String</lh-td>
      <lh-td>-</lh-td>
      <lh-td>false</lh-td>
    </lh-tr>
    <lh-tr>
      <lh-td>widht</lh-td>
      <lh-td>对话框宽度</lh-td>
      <lh-td>String</lh-td>
      <lh-td>-</lh-td>
      <lh-td>auto</lh-td>
    </lh-tr>
  </lh-table>
</model>
</template>

<script>
import Model from '../../components/Model.vue'
export default {
  components: { Model },
  name:"DialogView",
  data(){
    return {
      visible:false,
      code1:
        "<template>\n"+
        "<lh-dialog :visible.sync=\"visible\">\n"+
        "  <div slot=\"title\">标题</div>\n"+
        "  这是对话框内容，默认插槽......<br/>\n"+
        "  这是对话框内容，默认插槽......<br/>\n"+
        "  这是对话框内容，默认插槽......<br/>\n"+
        "  <div slot=\"footer\">\n"+
        "    <lh-button @click=\"visible=false\" backgroundColor=\"#409EFF20\" color=\"#409EFF\" border=\"1px\">取消</lh-button>\n"+
        "    <lh-button>确认</lh-button>\n"+
        "  </div>\n"+
        "</lh-dialog>\n"+
        "</template>\n\n"+
        "<script>\n"+
        "export default {\n"+
        "  data(){\n"+
        "    return {\n"+
        "      visible:false\n"+
        "    }\n"+
        "  }\n"+
        "}\n"+
        "</scr"+"ipt>"
        
    }
  }
}
</script>

<style lang="less" scoped>
.code{
  display: flex;
  flex-direction: column;
}
</style>