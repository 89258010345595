<template>
<div class="home">
    <!-- home
    <lh-router-link to="/lhUI" padding="4px">lhUI</lh-router-link>
    <lh-router-link to="/codeGenerate" padding="4px">codeGenerate</lh-router-link> -->
    <!-- <lh-router-link to="/aa" padding="4px" activeBorder="left">sdfsd</lh-router-link>
    <a href="http://localhost:8081/#/lhUI" target="_blank">lhui</a>d
    <a href="https://www.cnblogs.com/hellocd/p/10443237.html" target="_blank">flex</a> -->
    <!-- <lh-button @click="test">button</lh-button> -->
    <!-- <div class="content">
      <lh-router-link to="/lhUI" target="_blank">
        <lh-button :fontSize="fontSize" :color="color" :backgroundColor="backgroundColor" padding="30px">
          <img :src="getFavicon('lhUI')"/>
          lhUI
        </lh-button>
      </lh-router-link>
      <lh-router-link to="/codeGenerate" target="_blank">
        <lh-button :fontSize="fontSize" :color="color" :backgroundColor="backgroundColor" padding="30px">
          <img :src="getFavicon('/codeGenerate')"/>
          codeGenerate
        </lh-button>
      </lh-router-link>
      <lh-router-link to="https://www.bilibili.com/" target="_blank">
        <lh-button :fontSize="fontSize" :color="color" :backgroundColor="backgroundColor" padding="30px">
          <img :src="getFavicon('https://www.bilibili.com/')"/>
          bilibili
        </lh-button>
      </lh-router-link>
    </div> -->
  <div class="content">
    <link-model to="/lhUI">lhUI</link-model>
    <!-- <link-model to="/codeGenerate">codeGenerate</link-model> -->
    <link-model to="https://www.bilibili.com/">bilibili</link-model>
    <link-model to="https://browser.lenovo.com.cn/ai/?f=steer">AI导航</link-model>
  </div>
</div>
</template>

<script>
import LinkModel from '../components/LinkModel.vue';
//import {LhButton} from "lianghang-ui"
export default {
  components: { LinkModel },
  // components:{
  //   LhButton
  // },
  name:"Home",
  methods:{
    test:function(){
      //console.log("test");
      // let url=this.$router.resolve("/lhUI");
      // window.open(url.href,"_blank");
      this.getFavicon("https://www.bilibili.com/");
    }
  }
}
</script>

<style lang="less" scoped>
.home{
  width:100%;
  height: 100%;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  .content{
    // width:70%;
    //height: 60%;
    display: flex;
    // align-items: flex-start;
    // justify-content: flex-start;
    flex-wrap: wrap;
    //border: solid;
  }
}
</style>