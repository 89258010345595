<template>
  <model width="auto" title="Pagination" tag="分页">
    <h3>基础用法</h3>
    <p>通过 fontSize 属性控制分页组件大小。通过 activeColor 属性可以设置组件激活颜色。</p>
    <div>
      <div><lh-pagination fontSize="20px" activeColor="blue"/></div>
      <div><lh-pagination fontSize="25px" activeColor="red"/></div>
      <div><lh-pagination fontSize="30px" activeColor="green"/></div>
    </div>
    <div class="code">
      <lh-collapse>
        <div slot="title">示例代码</div>
        <lh-code-editor v-model="code1"></lh-code-editor>
      </lh-collapse>
    </div>
    <p>value(v-model) 属性为当前页码，pageSize 属性为页的大小，total 属性为数据总数，change 事件在页码改变时触发。</p>
    <div><lh-pagination v-model="currentPage" @change="change" :pageSize="5" :total="50"/></div>
    <lh-collapse>
      <div slot="title">示例代码</div>
      <lh-code-editor v-model="code2"></lh-code-editor>
    </lh-collapse>
    <h3>属性事件参考表</h3>
    <h4>属性参考表：</h4>
    <lh-table>
      <lh-tr>
        <lh-th>参数</lh-th>
        <lh-th>说明</lh-th>
        <lh-th>类型</lh-th>
        <lh-th>可选值</lh-th>
        <lh-th>默认值</lh-th>
      </lh-tr>
      <lh-tr>
        <lh-td>value(v-model)</lh-td>
        <lh-td>当前页码</lh-td>
        <lh-td>Number</lh-td>
        <lh-td>-</lh-td>
        <lh-td>1</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>pageSize</lh-td>
        <lh-td>页的大小</lh-td>
        <lh-td>Number</lh-td>
        <lh-td>-</lh-td>
        <lh-td>5</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>total</lh-td>
        <lh-td>总数</lh-td>
        <lh-td>Number</lh-td>
        <lh-td>-</lh-td>
        <lh-td>50</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>pagerCount</lh-td>
        <lh-td>页码按钮数</lh-td>
        <lh-td>Number</lh-td>
        <lh-td>-</lh-td>
        <lh-td>5</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>fontSize</lh-td>
        <lh-td>组件字体大小</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>20px</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>activeColor</lh-td>
        <lh-td>组件激活颜色</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>#409EFF</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>color</lh-td>
        <lh-td>组件字体颜色</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>black</lh-td>
      </lh-tr>
    </lh-table>
    <h4>事件参考表：</h4>
    <lh-table>
      <lh-tr>
        <lh-th>事件名称</lh-th>
        <lh-th>说明</lh-th>
        <lh-th>回调参数</lh-th>
      </lh-tr>
      <lh-tr>
        <lh-td>change</lh-td>
        <lh-td>页码发生变化触发事件</lh-td>
        <lh-td>更新后的值</lh-td>
      </lh-tr>
    </lh-table>
  </model>
</template>

<script>
import Model from '../../components/Model.vue';
export default {
  components: { Model },
  name:"PaginationView",
  data(){
    return{
      code1:
        "<div><lh-pagination fontSize=\"20px\" activeColor=\"blue\"/></div>\n"+
        "<div><lh-pagination fontSize=\"25px\" activeColor=\"red\"/></div>\n"+
        "<div><lh-pagination fontSize=\"30px\" activeColor=\"green\"/></div>",
      code2:
        "<template>\n"+
        "<div>\n"+
        "  <lh-pagination v-model=\"currentPage\" @change=\"change\" :pageSize=\"5\" :total=\"50\"/>\n"+
        "</div>\n"+
        "</template>\n\n"+
        "export default {\n"+
        "  data(){\n"+
        "    return {\n"+
        "      currentPage:1\n"+
        "    }\n"+
        "  },\n"+
        "  methods:{\n"+
        "    change:function(pageNum){\n"+
        "      console.log(\"change:\",pageNum);\n"+
        "    }\n"+
        "  }\n"+
        "}",
      currentPage:1
    }
  },
  methods:{
    change:function(pageNum){
      console.log("change:",pageNum);
    }
  }
}
</script>

<style lang="less" scoped>
.code{
  display: flex;
  flex-direction: column;
}
</style>