const routes=[
  {
    path:'/v1.0.2',
    meta:{
      tag:"1.0.2"
    },
    component:()=>import("../views/log/V1-0-2.vue")
  },
  {
    path:"/v2.0.0",
    meta:{
      tag:"2.0.0"
    },
    component:()=>import("../views/log/V2-0-0.vue")
  }
]
export default routes;