<template>
  <model width="auto" title="Radio" tag="单选按钮">
    <h3>基础用法</h3>
    <lh-radio fontSize="15px" v-model="selectValue" radioValue="value1">选项1</lh-radio>
    <lh-radio fontSize="20px" v-model="selectValue" radioValue="value2">选项2</lh-radio>
    <lh-radio :disabled="true" fontSize="25px" v-model="selectValue" radioValue="value3">选项3</lh-radio>
    <lh-radio v-if="betweenVesions(version,'2.0.0',null)" fontSize="30px" v-model="selectValue" radioValue="value4">选项4</lh-radio>
    <div class="code">
      <lh-collapse>
        <div slot="title">示例代码</div>
        <lh-code-editor v-model="code" :readonly="true"></lh-code-editor>
      </lh-collapse>
    </div>
    <h3>属性参考表</h3>
    <lh-table>
      <lh-tr>
        <lh-th>参数</lh-th>
        <lh-th>说明</lh-th>
        <lh-th>类型</lh-th>
        <lh-th>可选值</lh-th>
        <lh-th>默认值</lh-th>
      </lh-tr>
      <lh-tr>
        <lh-td>value(v-model)</lh-td>
        <lh-td>绑定的值</lh-td>
        <lh-td>-</lh-td>
        <lh-td>-</lh-td>
        <lh-td></lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>radioValue</lh-td>
        <lh-td>组件的值</lh-td>
        <lh-td>-</lh-td>
        <lh-td>-</lh-td>
        <lh-td></lh-td>
      </lh-tr>
      <lh-tr v-if="betweenVesions(version,'2.0.0',null)">
        <lh-td>disabled</lh-td>
        <lh-td>是否禁用按钮</lh-td>
        <lh-td>Boolean</lh-td>
        <lh-td>true | false</lh-td>
        <lh-td>false</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>fontSize</lh-td>
        <lh-td>字体大小</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>15px</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>margin</lh-td>
        <lh-td>组件外边距</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>2px</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>activeColor</lh-td>
        <lh-td>激活颜色</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>#409EFF</lh-td>
      </lh-tr>
    </lh-table>
  </model>
</template>

<script>
import Model from '../../components/Model.vue'
import { betweenVesions } from '../../utils/Index';
export default {
  name:"RadioView",
  components: { Model },
  data(){
    return{
      selectValue:"value1",
      version:"2.0.0",
      code:""
    }
  },
  methods:{
    handleVersionChange:function(){
      // console.log("version:",event);
      this.version=sessionStorage.getItem("version");
      let v2_0_0_code="";
      if(betweenVesions(this.version,'2.0.0',null)){
        v2_0_0_code="  <lh-radio :disabled=\"true\" fontSize=\"30px\" v-model=\"selectValue\" radioValue=\"value4\">选项4</lh-radio>\n";
      }
      this.code="<template>\n"+
        "<div>\n"+
        "  <lh-radio fontSize=\"15px\" v-model=\"selectValue\" radioValue=\"value1\">选项1</lh-radio>\n"+
        "  <lh-radio fontSize=\"20px\" v-model=\"selectValue\" radioValue=\"value2\">选项2</lh-radio>\n"+
        "  <lh-radio fontSize=\"25px\" v-model=\"selectValue\" radioValue=\"value3\">选项3</lh-radio>\n"+
        v2_0_0_code+
        "</div>\n"+
        "</template>\n\n"+
        "<script>\n"+
        "export default {\n"+
        "  data(){\n"+
        "    return{\n"+
        "      selectValue:\"value1\"\n"+
        "    }\n"+
        "  }\n"+
        "}\n"+
        "</scr"+"ipt>"
    },
    betweenVesions:betweenVesions
  },
  beforeMount:function(){
    this.handleVersionChange();
  },
  mounted:function(){
    window.addEventListener("versionChange",this.handleVersionChange);
  },
  beforeDestroy:function(){
    window.removeEventListener('versionChange', this.handleVersionChange);
  }
}
</script>

<style lang="less" scoped>
.code{
  display: flex;
  flex-direction: column;
}
</style>