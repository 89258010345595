<template>
<model width="auto" title="SizeLayout" tag="大小屏布局">
  <h3>效果展示</h3>
  <div class="content">
    <div class="item">
      <lh-img margin="5px" width="260px" :src="require('../../static/dp.jpg')"></lh-img>
      <span>大屏效果</span>
    </div>
    <div class="item">
      <div>
        <lh-img margin="5px" width="130px" :src="require('../../static/xp1.jpg')"></lh-img>
        <lh-img margin="5px" width="130px" :src="require('../../static/xp2.jpg')"></lh-img>
      </div>
      <span>小屏效果</span>
    </div>
  </div>
  <p>当屏幕宽度小于或等于指定值时，此组件自动将左侧导航栏折叠，此时点击展开按钮可弹出折叠的导航栏。</p>
  <lh-collapse>
    <div slot="title">示例代码</div>
    <lh-code-editor v-model="code"></lh-code-editor>
  </lh-collapse>
  <h3>属性参考表</h3>
  <lh-table>
    <lh-tr>
      <lh-th>参数</lh-th>
      <lh-th>说明</lh-th>
      <lh-th>类型</lh-th>
      <lh-th>可选值</lh-th>
      <lh-th>默认值</lh-th>
    </lh-tr>
    <lh-tr>
      <lh-td>smallWidth</lh-td>
      <lh-td>小屏宽度</lh-td>
      <lh-td>Number</lh-td>
      <lh-td>-</lh-td>
      <lh-td>450</lh-td>
    </lh-tr>
    <lh-tr>
      <lh-td>buttonColor</lh-td>
      <lh-td>展开按钮颜色</lh-td>
      <lh-td>String</lh-td>
      <lh-td>-</lh-td>
      <lh-td>#409EFF</lh-td>
    </lh-tr>
  </lh-table>
</model>
</template>

<script>
import Model from '../../components/Model.vue'
export default {
  components: { Model },
  name:"SizeLayoutView",
  data(){
    return{
      code:
        "<template>\n"+
        "<div>\n"+
        "  ...\n"+
        "  此处省略头部代码\n"+
        "  ...\n"+
        "  <lh-size-layout>\n"+
        "    <div slot=\"left\">\n"+
        "      ...\n"+
        "      此处省略左侧导航栏代码\n"+
        "      ...\n"+
        "    </div>\n"+
        "    <div>\n"+
        "      ...\n"+
        "      此处省略右侧主体内容代码\n"+
        "      ...\n"+
        "    </div>\n"+
        "  </lh-size-layout>\n"+
        "</div>\n"+
        "</template>"
    }
  }
}
</script>

<style lang="less" scoped>
.content{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .item{
    display: flex;
    flex-direction: column;
    align-items: center;
    span{
      font-weight: 550;
      margin: 5px;
    }
  }
}
</style>