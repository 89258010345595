<template>
<model width="auto" title="Drawer" tag="抽屉">
<div class="drawerView">
  <h3>基础用法</h3>
  <div>
    <lh-radio v-model="direction" radioValue="top">上</lh-radio>
    <lh-radio v-model="direction" radioValue="bottom">下</lh-radio>
    <lh-radio v-model="direction" radioValue="left">左</lh-radio>
    <lh-radio v-model="direction" radioValue="right">右</lh-radio>
  </div>
  <div>
    <lh-button @click="visible=true">点击打开抽屉</lh-button>
  </div>
  <lh-drawer :visible.sync="visible" :direction="direction">
    <div>这是抽屉内容...</div>
    <div>这是抽屉内容...</div>
    <div>这是抽屉内容...</div>
    <div>这是抽屉内容...</div>
    <div>这是抽屉内容...</div>
    <div>这是抽屉内容...</div>
    <div>这是抽屉内容...</div>
    <div>这是抽屉内容...</div>
  </lh-drawer>
  <lh-collapse>
    <div slot="title">示例代码</div>
    <lh-code-editor v-model="code" :readonly="true"></lh-code-editor>
  </lh-collapse>
  <h3>属性参考表</h3>
  <lh-table>
    <lh-tr>
      <lh-th>参数</lh-th>
      <lh-th>说明</lh-th>
      <lh-th>类型</lh-th>
      <lh-th>可选值</lh-th>
      <lh-th>默认值</lh-th>
    </lh-tr>
    <lh-tr>
      <lh-td>visible(.sync)</lh-td>
      <lh-td>是否弹出抽屉</lh-td>
      <lh-td>String</lh-td>
      <lh-td>-</lh-td>
      <lh-td>false</lh-td>
    </lh-tr>
    <lh-tr>
      <lh-td>direction</lh-td>
      <lh-td>弹出方向</lh-td>
      <lh-td>String</lh-td>
      <lh-td>top | bottom | left | right</lh-td>
      <lh-td>left</lh-td>
    </lh-tr>
  </lh-table>
</div>
</model>
</template>

<script>
import Model from '../../components/Model.vue';
export default {
  components: {
    Model
  },
  name:"DrawerView",
  data:function(){
    return{
      visible:false,
      direction:"left",
      code:
      "<template>\n"+
      "  <div>\n"+
      "    <lh-radio v-model=\"direction\" radioValue=\"top\">上</lh-radio>\n"+
      "    <lh-radio v-model=\"direction\" radioValue=\"bottom\">下</lh-radio>\n"+
      "    <lh-radio v-model=\"direction\" radioValue=\"left\">左</lh-radio>\n"+
      "    <lh-radio v-model=\"direction\" radioValue=\"right\">右</lh-radio>\n"+
      "  </div>\n"+
      "  <lh-button @click=\"visible=true\">点击打开抽屉</lh-button>\n"+
      "  <lh-drawer :visible.sync=\"visible\" :direction=\"direction\">\n"+
      "    <div>这是抽屉内容...</div>\n"+
      "    <div>这是抽屉内容...</div>\n"+
      "    <div>这是抽屉内容...</div>\n"+
      "    <div>这是抽屉内容...</div>\n"+
      "    <div>这是抽屉内容...</div>\n"+
      "    <div>这是抽屉内容...</div>\n"+
      "    <div>这是抽屉内容...</div>\n"+
      "  </lh-drawer>\n"+
      "</template>\n\n"+
      "<script>\n"+
      "export default{\n"+
      "  data:funtion(){\n"+
      "    return{\n"+
      "      visible:false,\n"+
      "      direction:\"left\"\n"+
      "    }\n"+
      "  }\n"+
      "}\n"+
      "</scr"+"ipt>"
    }
  }
}
</script>

<style scope lang="less">
.drawerView{
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  // .code{
  //   display: flex;
  //   flex-direction: column;
  // }
}
</style>