const compareVersion=function(version1,version2){
  let v1s=version1.split(".");
  let v2s=version2.split(".");
  for(let i=0;i<v1s.length;i++){
    if(v1s[i]>v2s[i]){
      return ">";
    }else if(v1s[i]<v2s[i]){
      return "<";
    }
  }
  return "=";
}

export const betweenVesions=function(currentVersion,startVersion,endVersion){
  if(startVersion!=undefined&&startVersion!=null){
    let res=compareVersion(currentVersion,startVersion);
    if(res=="<"){
      return false;
    }
  }
  if(endVersion!=undefined&&endVersion!=null){
    let res=compareVersion(currentVersion,endVersion);
    if(res==">"){
      return false;
    }
  }
  return true;
}