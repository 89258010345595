<template>
  <div :style="{width:width,padding:padding}" class="model">
    <div class="head">
      <div><h1>{{title}}</h1></div>
      <div><h3>{{tag}}</h3></div>
    </div>
    <div class="modelContent">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name:"Model",
  props:{
    title:{
      type:String,
      default:"title"
    },
    tag:{
      type:String,
      default:""
    },
    width:{
      type:String,
      default:"820px"
    },
    padding:{
      type:String,
      default:"15px"
    }
  }
}
</script>

<style lang="less" scoped>
.model{
  // width:500px;
  //max-width: 800px;
  //width: 800px;
  &>.head{
    //border:solid;
    width: 100%;
    display:flex;
    justify-content:space-between;
    align-items:flex-end;
    border-bottom:solid 2px rgba(0,0,0,0.3);
  }
  box-shadow:0 3px 8px rgba(0,0,0,0.2);
  //padding:15px;
  // margin:10px;
  &>.modelContent{
    padding:10px;
  }
}
</style>