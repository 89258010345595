<template>
  <div>
    <model width="auto" title="v1.0.2" tag="2024-01-02">
      <div class="lh-icon-logo" :style="{fontSize:logoFontSize}">
        <span :style="{fontSize:titleFontSize}">lhUI</span>
      </div>
      <div class="foot">
        Hello world! <br/> 
        第一个正式版本<br/>
        author:两行
      </div>
    </model>
  </div>
</template>

<script>
import Model from '../../components/Model.vue'
export default {
  name:"V1.0.2",
  components: { Model },
  data:function(){
    return{
      logoFontSize:"70px",
      titleFontSize:"35px"
    }
  },
  methods:{
    handleResize:function(){
      this.logoFontSize=document.documentElement.clientWidth/4*0.6+"px";
      this.titleFontSize=document.documentElement.clientWidth/4*0.4+"px";
    }
  },
  mounted:function(){
    this.handleResize();
    window.addEventListener("resize",this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style lang="less" scoped>
.lh-icon-logo{
  font-size: 200px;
  span{
    font-size: 100px;
    font-weight: 700;
  }
}
.foot{
  font-weight: 700;
}
</style>