<template>
<model width="auto" title="快速上手">
  <h3>引入lhUI</h3>
  <p>你可以引入整个 lhUI ，或是根据需要仅引入部分组件。我们先介绍如何引入完整的 lhUI 。</p>
  <h4>完整引入</h4>
  <p>在 main.js 中写入以下内容：</p>
  <lh-code-editor :downloadButton="false" :copyButton="false" readonly backgroundColor="black" v-model="wiCode"></lh-code-editor>
  <p>以上代码便完成了 lhUI 的引入。</p>
  <h4>按需引入</h4>
  <p>如果你只希望引入部分组件，比如 LhButton 和 LhSelect，那么需要在 main.js 中写入以下内容：</p>
  <lh-code-editor :downloadButton="false" :copyButton="false" readonly backgroundColor="black" v-model="biCode"></lh-code-editor>
</model>
</template>

<script>
import Model from "../../components/Model"
export default {
  name:"Start",
  components: { Model },
  data(){
    return{
      wiCode:
            "import Vue from 'vue';\n"+
            "import App from './App.vue';\n"+
            "import lhUI from 'lianghang-ui';\n\n"+
            "Vue.use(lhUI);\n\n"+
            "new Vue({\n"+
            "  el: '#app',\n"+
            "  render: h => h(App)\n"+
            "});\n",
      biCode:
            "import Vue from 'vue';\n"+
            "import App from './App.vue';\n"+
            "import { LhButton, LhSelect } from 'lianghang-ui';\n\n"+
            "Vue.component(LhButton.name, LhButton);\n"+
            "Vue.component(LhSelect.name, LhSelect);\n\n"+
            "new Vue({\n"+
            "  el: '#app',\n"+
            "  render: h => h(App)\n"+
            "});"
    }
  }
}
</script>

<style lang="less" scoped>
// p{
//   text-indent: 2em;
// }
</style>