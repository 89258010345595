<template>
  <div class="buttonView">
    <model width="auto" title="Button" tag="按钮">
      <p>常用操作按钮。</p>
      <div class="content">
        <lh-collapse contentPadding="15px">
          <div slot="title">按钮大小</div>
          <p>通过 padding 属性和 fontSize 属性控制按钮大小。</p>
          <p>padding:</p>
          <div class="butList">
            <lh-button padding="5px">按钮1</lh-button>
            <lh-button padding="10px">按钮2</lh-button>
            <lh-button padding="15px">按钮3</lh-button>
          </div>
          <lh-code-editor v-model="code.size.code1"></lh-code-editor>
          <p>fontSize:</p>
          <div>
            <lh-button fontSize="10px">按钮1</lh-button>
            <lh-button fontSize="15px">按钮2</lh-button>
            <lh-button fontSize="20px">按钮3</lh-button>
          </div>
          <lh-code-editor v-model="code.size.code2"></lh-code-editor>
          <p>此外，通过 width 属性可以设置按钮的宽度。</p>
          <div>
            <lh-button width="100px">按钮1</lh-button>
            <lh-button width="120px">按钮2</lh-button>
            <lh-button width="140px">按钮3</lh-button>
          </div>
          <lh-code-editor v-model="code.size.code3"></lh-code-editor>
        </lh-collapse>
        <lh-collapse contentPadding="15px">
          <div slot="title">按钮形状</div>
          <h4>按钮圆角：</h4>
          <p>通过 borderRadius 属性可以设置按钮圆角。当 borderRadius="full" 时，按钮为全圆角。</p>
          <div>
            <lh-button borderRadius="5px">按钮1</lh-button>
            <lh-button borderRadius="10px">按钮2</lh-button>
            <lh-button borderRadius="full">按钮3</lh-button>
          </div>
          <lh-code-editor v-model="code.shape.code1"></lh-code-editor>
          <h4>正方形按钮：</h4>
          <p>通过 type 属性可以设置按钮类型，当 type="square" 时，按钮为正方形。</p>
          <div><lh-button type="square">方形</lh-button></div>
          <lh-code-editor v-model="code.shape.code2"></lh-code-editor>
          <h4>圆形按钮：</h4>
          <p>在方形按钮的基础上，我们将 borderRadius 属性设置为"full"即可得到一个圆形按钮。</p>
          <div><lh-button type="square" borderRadius="full">圆形</lh-button></div>
          <lh-code-editor v-model="code.shape.code3"></lh-code-editor>
        </lh-collapse>
        <lh-collapse contentPadding="15px">
          <div slot="title">按钮颜色</div>
          <h4>文字颜色：</h4>
          <p>通过 color 属性可以修改按钮文字颜色。</p>
          <div>
            <lh-button color="#000000">按钮1</lh-button>
            <lh-button color="#FFFFFF">按钮2</lh-button>
            <lh-button color="#FFF200">按钮3</lh-button>
          </div>
          <lh-code-editor v-model="code.color.code1"></lh-code-editor>
          <h4>背景颜色：</h4>
          <p>通过 backgroundColor 属性可以修改按钮背景颜色。</p>
          <div>
            <lh-button backgroundColor="#409EFF">按钮1</lh-button>
            <lh-button backgroundColor="#67C23A">按钮2</lh-button>
            <lh-button backgroundColor="#909399">按钮3</lh-button>
            <lh-button backgroundColor="#E6A23C">按钮4</lh-button>
            <lh-button backgroundColor="#F56E6E">按钮5</lh-button>
          </div>
          <div>
            <lh-button backgroundColor="#409EFF20" color="#409EFF" border="1px">透明1</lh-button>
            <lh-button backgroundColor="#67C23A20" color="#67C23A" border="1px">透明2</lh-button>
            <lh-button backgroundColor="#90939920" color="#909399" border="1px">透明3</lh-button>
            <lh-button backgroundColor="#E6A23C20" color="#E6A23C" border="1px">透明4</lh-button>
            <lh-button backgroundColor="#F56E6E20" color="#F56E6E" border="1px">透明5</lh-button>
          </div>
          <lh-code-editor v-model="code.color.code2"></lh-code-editor>
        </lh-collapse>
        <lh-collapse contentPadding="15px">
          <div slot="title">图标与状态</div>
          <h4>设置按钮图标：</h4>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;通过 icon 属性可以设置按钮图标。通过设置 icon 属性为图标的类名即可设置按钮图标。图标类名请参考上一节 <lh-router-link to="/icon" fontSize="18px" color="#409EFF">Icon 图标</lh-router-link> 。</p>
          <div>
            <lh-button icon="lh-icon-add">添加</lh-button>
            <lh-button icon="lh-icon-update">修改</lh-button>
            <lh-button icon="lh-icon-search">搜索</lh-button>
            <lh-button icon="lh-icon-delete" backgroundColor="red">删除</lh-button>
          </div>
          <div>
            <lh-button icon="lh-icon-add" type="square" borderRadius="full"></lh-button>
            <lh-button icon="lh-icon-update" type="square" borderRadius="full"></lh-button>
            <lh-button icon="lh-icon-search" type="square" borderRadius="full"></lh-button>
            <lh-button icon="lh-icon-delete" type="square" borderRadius="full" backgroundColor="red"></lh-button>
          </div>
          <lh-code-editor v-model="code.icon.code1"></lh-code-editor>
          <h4>按钮状态：</h4>
          <p>通过 disabled 属性可以设置按钮是否为禁用状态。</p>
          <div><lh-button :disabled="true">禁用按钮</lh-button></div>
          <lh-code-editor v-model="code.icon.code2"></lh-code-editor>
          <p>通过 loading 属性可以将按钮设置为加载中状态。</p>
          <div><lh-button :loading="true">加载中</lh-button></div>
          <lh-code-editor v-model="code.icon.code3"></lh-code-editor>
        </lh-collapse>
        <lh-collapse>
          <div slot="title">属性参考表</div>
          <lh-table>
            <lh-tr>
              <lh-th>参数</lh-th>
              <lh-th width="250px">说明</lh-th>
              <lh-th>类型</lh-th>
              <lh-th>可选值</lh-th>
              <lh-th>默认值</lh-th>
            </lh-tr>
            <lh-tr>
              <lh-td>width</lh-td>
              <lh-td>按钮宽度</lh-td>
              <lh-td>String</lh-td>
              <lh-td>-</lh-td>
              <lh-td>auto</lh-td>
            </lh-tr>
            <lh-tr>
              <lh-td>fontSize</lh-td>
              <lh-td>文字大小</lh-td>
              <lh-td>String</lh-td>
              <lh-td>-</lh-td>
              <lh-td>15px</lh-td>
            </lh-tr>
            <lh-tr>
              <lh-td>fontWeight</lh-td>
              <lh-td>文字粗细</lh-td>
              <lh-td>Number</lh-td>
              <lh-td>-</lh-td>
              <lh-td>500</lh-td>
            </lh-tr>
            <lh-tr>
              <lh-td>margin</lh-td>
              <lh-td>按钮外边距</lh-td>
              <lh-td>String</lh-td>
              <lh-td>-</lh-td>
              <lh-td>3px</lh-td>
            </lh-tr>
            <lh-tr>
              <lh-td>padding</lh-td>
              <lh-td>按钮内边距</lh-td>
              <lh-td>String</lh-td>
              <lh-td>-</lh-td>
              <lh-td>7px</lh-td>
            </lh-tr>
            <lh-tr>
              <lh-td>color</lh-td>
              <lh-td>文字颜色</lh-td>
              <lh-td>String</lh-td>
              <lh-td>-</lh-td>
              <lh-td>aliceblue</lh-td>
            </lh-tr>
            <lh-tr>
              <lh-td>borderRadius</lh-td>
              <lh-td>按钮圆角</lh-td>
              <lh-td>String</lh-td>
              <lh-td>-</lh-td>
              <lh-td>4px</lh-td>
            </lh-tr>
            <lh-tr>
              <lh-td>border</lh-td>
              <lh-td>按钮边框</lh-td>
              <lh-td>String</lh-td>
              <lh-td>-</lh-td>
              <lh-td>none</lh-td>
            </lh-tr>
            <lh-tr>
              <lh-td>disabled</lh-td>
              <lh-td>是否禁用按钮</lh-td>
              <lh-td>Boolean</lh-td>
              <lh-td>true | false</lh-td>
              <lh-td>false</lh-td>
            </lh-tr>
            <lh-tr>
              <lh-td>loading</lh-td>
              <lh-td>是否加载中</lh-td>
              <lh-td>Boolean</lh-td>
              <lh-td>true | false</lh-td>
              <lh-td>false</lh-td>
            </lh-tr>
            <lh-tr>
              <lh-td>type</lh-td>
              <lh-td>按钮类型</lh-td>
              <lh-td>String</lh-td>
              <lh-td>normal | square</lh-td>
              <lh-td>normal</lh-td>
            </lh-tr>
            <lh-tr>
              <lh-td>accept</lh-td>
              <lh-td>文件格式，设置此属性，则按钮变为文件上传按钮，多个值用逗号隔开。</lh-td>
              <lh-td>String</lh-td>
              <lh-td>.jpg | .png | * | ...</lh-td>
              <lh-td>null</lh-td>
            </lh-tr>
          </lh-table>
        </lh-collapse>
      </div>
    </model>
  </div>
</template>

<script>
import Model from '../../components/Model.vue'
export default {
  components: { Model },
  name:"ButtonView",
  data(){
    return {
      code:{
        size:{
          code1:
            "<lh-button padding=\"5px\">按钮1</lh-button>\n"+
            "<lh-button padding=\"10px\">按钮2</lh-button>\n"+
            "<lh-button padding=\"15px\">按钮3</lh-button>",
          code2:
            "<lh-button fontSize=\"10px\">按钮1</lh-button>\n"+
            "<lh-button fontSize=\"15px\">按钮2</lh-button>\n"+
            "<lh-button fontSize=\"20px\">按钮3</lh-button>",
          code3:
            "<lh-button width=\"100px\">按钮1</lh-button>\n"+
            "<lh-button width=\"120px\">按钮2</lh-button>\n"+
            "<lh-button width=\"140px\">按钮3</lh-button>",
        },
        shape:{
          code1:
            "<lh-button borderRadius=\"5px\">按钮1</lh-button>\n"+
            "<lh-button borderRadius=\"10px\">按钮2</lh-button>\n"+
            "<lh-button borderRadius=\"full\">按钮3</lh-button>",
          code2:
            "<lh-button type=\"square\">方形</lh-button>",
          code3:
            "<lh-button type=\"square\" borderRadius=\"full\">圆形</lh-button>"
        },
        color:{
          code1:
            "<lh-button color=\"#000000\">按钮1</lh-button>\n"+
            "<lh-button color=\"#FFFFFF\">按钮2</lh-button>\n"+
            "<lh-button color=\"#FFF200\">按钮3</lh-button>",
          code2:
            "<div>\n"+
            "  <lh-button backgroundColor=\"#409EFF\">按钮1</lh-button>\n"+
            "  <lh-button backgroundColor=\"#67C23A\">按钮2</lh-button>\n"+
            "  <lh-button backgroundColor=\"#909399\">按钮3</lh-button>\n"+
            "  <lh-button backgroundColor=\"#E6A23C\">按钮4</lh-button>\n"+
            "  <lh-button backgroundColor=\"#F56E6E\">按钮5</lh-button>\n"+
            "</div>\n"+
            "<div>\n"+
            "  <lh-button backgroundColor=\"#409EFF20\" color=\"#409EFF\" border=\"1px\">透明1</lh-button>\n"+
            "  <lh-button backgroundColor=\"#67C23A20\" color=\"#67C23A\" border=\"1px\">透明2</lh-button>\n"+
            "  <lh-button backgroundColor=\"#90939920\" color=\"#909399\" border=\"1px\">透明3</lh-button>\n"+
            "  <lh-button backgroundColor=\"#E6A23C20\" color=\"#E6A23C\" border=\"1px\">透明4</lh-button>\n"+
            "  <lh-button backgroundColor=\"#F56E6E20\" color=\"#F56E6E\" border=\"1px\">透明5</lh-button>\n"+
            "</div>"
        },
        icon:{
          code1:
            "<div>\n"+
            "  <lh-button icon=\"lh-icon-add\">添加</lh-button>\n"+
            "  <lh-button icon=\"lh-icon-update\">修改</lh-button>\n"+
            "  <lh-button icon=\"lh-icon-search\">搜索</lh-button>\n"+
            "  <lh-button icon=\"lh-icon-delete\" backgroundColor=\"red\">删除</lh-button>\n"+
            "</div>\n"+
            "<div>\n"+
            "  <lh-button icon=\"lh-icon-add\" type=\"square\" borderRadius=\"full\"></lh-button>\n"+
            "  <lh-button icon=\"lh-icon-update\" type=\"square\" borderRadius=\"full\"></lh-button>\n"+
            "  <lh-button icon=\"lh-icon-search\" type=\"square\" borderRadius=\"full\"></lh-button>\n"+
            "  <lh-button icon=\"lh-icon-delete\" type=\"square\" borderRadius=\"full\" backgroundColor=\"red\"></lh-button>\n"+
            "</div>",
          code2:
            "<lh-button :disabled=\"true\">禁用按钮</lh-button>",
          code3:
            "<lh-button :loading=\"true\">加载中</button>"
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.buttonView{
  .content{
    display: flex;
    flex-direction: column;
  }
  // p{
  //   text-indent:2em;
  // }
}
</style>